var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var currentNonce;

exports.setNonce = function (nonce) {
  currentNonce = nonce;
};

exports.getNonce = function () {
  if (currentNonce) {
    return currentNonce;
  }

  if (typeof __webpack_nonce__ !== "undefined") {
    return __webpack_nonce__;
  }

  return undefined;
};

export default exports;
export const __esModule = exports.__esModule,
      setNonce = exports.setNonce,
      getNonce = exports.getNonce;